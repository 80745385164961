<template>
  <div class="">
    <v-btn icon to="/" class="mt-2 ml-2" color="blue-grey lighten-3">
      <v-icon>fas fa-arrow-left</v-icon>
    </v-btn>
    <router-view> </router-view>
  </div>
</template>
<script>
export default {};
</script>
